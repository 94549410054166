.app {
    padding: 10px;
    text-align: center;
}

.sourceCode {
    position: absolute;
    top: 8px;
    right: 8px;
}

.subtitle {
    color: #c7c7c7;
}

.subsubtitle {
    color: #c7c7c7;
    font-size: 12px;
    margin-bottom: 42px;
}

.socialLinks {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.icon {
    width: 48px;
    height: 48px;
    transition: opacity 0.3s ease;
}

.icon:hover {
    opacity: 0.7;
}

.toggleButton {
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 10px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gameContainer {
	height: 75vh;
	padding: 0 !important;
	margin-left: auto !important;
	margin-right: auto !important;
	aspect-ratio: 256/240;
}

.viewOnlyMode {
    max-width: 100%;
    height: 88vh;
    margin-bottom: 0;
}

.modulesContainer {
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 100%;
    transition: max-height 0.5s ease, opacity 0.5s ease;
}

.module {
    width: 300px; 
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #000; 
    background-color: rgba(0, 0, 0, 0.5); 
    background-size: cover; 
    background-position: center; 
    color: #fff; 
}
