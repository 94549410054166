body {
	margin: 0;

	background-color: #000000;
	color: white;
}

.nes-balloon {
	color: black;
}

html,
body,
pre,
code,
kbd,
samp {
	font-family: "Press Start 2P";
}
